import { motion, AnimateSharedLayout } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";
import React, { useState, useCallback } from "react";
import "./expCard.scss";

function Content({
  title,
  tools,
  image,
  video,
  description,
  color,
  disabled,
  onCollapse,
  site,
  mobile,
  alt,
  proImage,
  cover,
}) {
  const $body = document.querySelector("body");
  const unlockScroll = () => {
    if ($body) {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("width");
    }
  };
  const handleClick = useCallback(() => {
    unlockScroll();
    onCollapse();
  }, [disabled, onCollapse]);
  return (
    <motion.div
      className="expCard-content"
      layoutId="title"
      style={{ opacity: disabled ? 0.2 : 1 }}
    >
      <div
        className="expCard-close"
        onClick={() => {
          handleClick();
        }}
      >
        <IoCloseSharp />
      </div>
      <motion.div className="expCard-image-container">
        <img className="expCard-image" src={image} alt={alt} />
      </motion.div>
      <motion.div className="content-container">
        <motion.div className="content-tools">
          <motion.div className="content-tools-item">{tools}</motion.div>
        </motion.div>
        <motion.a
          href={site}
          target="_blank"
          rel="noopener noreferrer"
          className="content-title"
          style={{ color: color }}
        >
          {title}
        </motion.a>
        <p>{description}</p>
        <div className="content-image-container">
          {!video ? null : mobile ? (
            <img className="content-image mobile" src={video} alt="" />
          ) : (
            <img className="content-image" src={video} alt="" />
          )}
          {!proImage ? null : !cover ? (
            <img className="imagePro" src={proImage} alt="" />
          ) : (
            <img className="imagePro cover" src={proImage} alt="" />
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}

function ExpandedCard({ children }) {
  return (
    <>
      <motion.div className="expCard expanded" layoutId="expandable-card">
        {children}
      </motion.div>
    </>
  );
}

function CompactCard({ children, onExpand, disabled }) {
  const $body = document.querySelector("body");
  const lockScroll = () => {
    if ($body) {
      $body.style.overflow = "hidden";
      $body.style.width = "100%";
    }
  };

  const handleClick = useCallback(() => {
    if (disabled) return;
    lockScroll();
    onExpand();
  }, [disabled, onExpand]);

  return (
    <motion.div
      className="expCard compact"
      layoutId="expandable-card"
      onClick={() => {
        handleClick();
      }}
      whileHover={{ scale: 1.05, cursor: "pointer" }}
    >
      <div className="compact-overlay" />
      {children}
    </motion.div>
  );
}

export default function ExpCard({
  title,
  tools,
  image,
  description,
  video,
  color,
  onCollapse,
  onExpand,
  disabled,
  site,
  mobile,
  alt,
  proImage,
  cover,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseDate = () => {
    setIsExpanded(false);
    onCollapse();
  };

  const expandDate = () => {
    setIsExpanded(true);
    onExpand();
  };
  return (
    <div className="expCard-container">
      <AnimateSharedLayout>
        {isExpanded ? (
          <ExpandedCard>
            <Content
              onCollapse={collapseDate}
              disabled={disabled}
              title={title}
              tools={tools}
              image={image}
              video={video}
              description={description}
              color={color}
              site={site}
              mobile={mobile}
              alt={alt}
              proImage={proImage}
              cover={cover}
            />
          </ExpandedCard>
        ) : (
          <CompactCard onExpand={expandDate} disabled={disabled}>
            <Content image={image} disabled={disabled} />
          </CompactCard>
        )}
      </AnimateSharedLayout>
    </div>
  );
}

import React, { useState } from "react";
import "./exp.scss";
import Guessfit from "../../images/GuessfitLogo.jpg";
import CJSF from "../../images/CJSFLogo.jpg";
import CJSFVideo from "../../videos/CJSF-work.gif";
import Leon from "../../images/LeonLogo.png";
import LeonVideo from "../../videos/Leon.gif";
import Mehregan from "../../images/MehreganLogo.png";
import MehreganVideo from "../../videos/Mehregan.gif";
import GuessfitImage from "../../images/Guessfit.png";
import Inobat from "../../images/Inobat1.png";
import Inobat2 from "../../images/Inobat2.png";
import InobatWeb from "../../images/image1.jpeg";
import { motion } from "framer-motion";
import ExpCard from "../ExpCard/ExpCard";
import Zenfeatlogo from "../../images/zenfeat.jpg";
import ZenfeatShow from "../../images/zenfeatshow.png";

export default function Exp() {
  const [expandedDay, setCollapsedDay] = useState();
  const EXP_DATA = [
    {
      title: "Zenfeat",
      tools: "ReactNative Development",
      description:
        "Zenfeat is a mobile application that allows the user to order dishes from their local neighbors(chefs). The application is a marketplace for chefs and food lovers.",
      image: Zenfeatlogo,
      proImage: ZenfeatShow,
      color: "#F09840",
      site: "https://zenfeat-app.webflow.io/",
      alt: "Zenfeat Logo",
      cover: false,
    },
    {
      title: "inobat.vercel.app",
      tools: "Next.js Development",
      description:
        "Inoobat is a web application that allows the clinics and hospitals to manage their patients and their appointments. Inoobat allows the patients to book their appointments online and to manage their appointments. Inoobat helps the clinics and hospitals to manage their patients and their appointments.",
      image: Inobat2,
      proImage: InobatWeb,
      color: "#3ab372",
      site: "https://inobat.vercel.app",
      alt: "Inoobat Logo",
      cover: true,
    },
    {
      title: "Guessfit",
      tools: "ReactNative Development",
      description:
        "Guessfit is a training mobile application for athletes. With its AI-driven coaching and meal planning, you can take the guesswork out of your workout routine and focus on reaching your fitness goals.",
      image: Guessfit,
      proImage: GuessfitImage,
      color: "#2B5098",
      isImage: true,
      site: "https://guessfit.ca",
      alt: "Guessfit Logo",
      cover: false,
    },
    {
      title: "CJSF",
      tools: "ReactNative Development",
      description:
        "The CJSF 90.1 FM application allows the user and the listeners to listen to On Air programs on CJSF 90.1 FM. The project started as a side project and was later expanded to a full blown web application.",
      image: CJSF,
      video: CJSFVideo,
      color: "#27C7BC",
      site: "https://cjsf.ca",
      mobile: true,
      alt: "CJSF Logo",
      cover: false,
    },
  ];
  return (
    <div className="exp container container__large" id="experiences">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        transition={{
          y: { type: "spring", damping: 15 },
          default: { duration: 1 },
        }}
        variants={{
          visible: { y: 0, opacity: 1 },
          hidden: { y: 100, opacity: 0 },
        }}
        className="exp-title"
      >
        Experiences
      </motion.h1>
      <div className="exp-cards">
        {EXP_DATA.map((exp) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
            className="exp-card-container"
          >
            <ExpCard
              title={exp.title}
              tools={exp.tools}
              description={exp.description}
              image={exp.image}
              video={exp.video}
              color={exp.color}
              disabled={expandedDay !== exp && expandedDay !== undefined}
              //@ts-ignore
              onExpand={() => setCollapsedDay(day)}
              //@ts-ignore
              onCollapse={() => setCollapsedDay()}
              key={exp.title}
              site={exp.site}
              mobile={exp.mobile}
              alt={exp.alt}
              proImage={exp.proImage}
              cover={exp.cover}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}
